import { message } from "antd";
import UploadImage from "Components/upload/UploadImage";
import { ChangeEvent, FormEvent, useState } from "react";
import {
  usePostStyleImagesMutation,
} from "redux/actions/services";
import { useNavigate } from "react-router-dom";

function EditStyle() {
  const [file, setFile] = useState("");
  const [styleImage, setStyleImage] = useState({
    styleImage1: "",
    styleImage2: "",
    styleImage3: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [postStyleImagesMutation] = usePostStyleImagesMutation();
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStyleImage({ ...styleImage, [name]: value });
  };
  const payload: any = {
    data: {
      styles: [
        styleImage.styleImage1,
        styleImage.styleImage2,
        styleImage.styleImage3,
      ],
    },
    url: "lifeStyleImages/lifeStyleImage",
  };
  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await postStyleImagesMutation(payload)
      .unwrap()
      .then((res) => {
        message.success(res.message);
        // setStatus(res.message);
        setIsLoading(false);
        navigate("/");
      })
      .catch((error) => {
        // setStatus("error");
        setIsLoading(false);
        // console.log("Error occurred:", error);
      });
  };
  return (
    <div>
      <form onSubmit={submitHandler} className="w-[50%] mx-auto">
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="styleImage1" className="basis-[13%] mr-2">
            Show case 1:
          </label>
          <input
            type="styleImage1"
            name="styleImage1"
            placeholder="Enter styleImage1"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            min="4"
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="styleImage2" className="basis-[13%] mr-2">
            Show case 2:
          </label>
          <input
            type="text"
            name="styleImage2"
            placeholder="Enter styleImage2"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={4}
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="styleImage2" className="basis-[13%] mr-2">
            Show case 3:
          </label>
          <input
            type="text"
            name="styleImage3"
            placeholder="Enter styleImage3"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={4}
            required
            onChange={changeHandler}
          />
        </div>
        {file ? (
          <div className="w-full p-2 rounded-xl bg-white">
            <p className="w-full break-words text-xs">{file}</p>
          </div>
        ) : null}
        <div>
          <UploadImage setFile={setFile} AltText="Upload Style" />
        </div>
        {/* <button className="w-[60%] my-8 bg-button p-2 rounded-md hover:bg-button_hover hover:text-white md:p-3">
          {isLoading ? <Spin size="large" /> : "upload"}
        </button> */}
        <button className="w-full my-8 bg-button p-2 rounded-md hover:bg-button_hover hover:text-white md:p-3">
          {isLoading ? "Loading..." : "Update Style Images"}
        </button>
      </form>
    </div>
  );
}

export default EditStyle;
