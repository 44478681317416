import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import App from "App";
import ProductCard from "Components/productcard/ProductCard";
import SignIn from "Components/signin/Signin";
import SignUp from "Components/signin/signup";
import AddProduct from "Components/addproduct/AddProduct";
import EditTab from "Components/EditSections/EditTab";

const Routemanager: React.FC = () => {
  const { token } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="product/:id" element={<ProductCard />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        {/* Protected Routes */}
        {token ? (
          <>
            <Route path="addproduct" element={<AddProduct />} />
            <Route path="edit" element={<EditTab />} />
          </>
        ) : (
          <>
            {/* Redirect with a message */}
            <Route
              path="addproduct"
              element={
                <Navigate
                  to="/signin"
                  state={{ message: "Please sign in to access this page." }}
                  replace
                />
              }
            />
            <Route
              path="edit"
              element={
                <Navigate
                  to="/signin"
                  state={{ message: "Please sign in to edit a product." }}
                  replace
                />
              }
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Routemanager;
