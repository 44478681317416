import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { EditSlider, EditStyle, EditContactInfo } from "./EditIndex";
import Header from "Components/header/Header";

function EditTab() {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Edit Hero images",
      children: <EditSlider />,
    },
    {
      key: "2",
      label: "Edit Customer Showcase",
      children: <EditStyle />,
    },
    {
      key: "3",
      label: "Edit Contact Info",
      children: <EditContactInfo />,
    },
  ];

  return (
    <div>
      <Header />
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        tabBarStyle={{ margin: "5rem 0 0 0" }}
      />
    </div>
  );
}

export default EditTab;
