import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const poiseApi = createApi({
  reducerPath: "poiseApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.thisispoise.com/" }),
  // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:8080/" }),

  tagTypes: [
    "Products",
    "Product",
    "Edit",
    "HeroImages",
    "StyleImages",
    "Contact",
  ],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (url) => `${url}`,
      providesTags: ["Products"],
    }),
    getProduct: builder.query({
      query: (id) => `products/product/${id}`,
      providesTags: ["Product"],
    }),
    getHeroImages: builder.query({
      query: (url) => `/heroImages${url}`,
      providesTags: ["HeroImages"],
    }),
    getStyleImages: builder.query({
      query: (url) => `/lifeStyleImages${url}`,
      providesTags: ["StyleImages"],
    }),
    getContactInfo: builder.query({
      query: (url) => `/contacts${url}`,
      providesTags: ["Contact"],
    }),
    postSignedIn: builder.mutation({
      query: (payload) => ({
        url: `/${payload.url}`,
        method: "POST",
        body: payload.data,
        headers: { "Content-type": "application/json" },
      }),
    }),
    postHeroImages: builder.mutation({
      query: (payload) => ({
        url: `/${payload.url}`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    postStyleImages: builder.mutation({
      query: (payload) => ({
        url: `/${payload.url}`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    postContact: builder.mutation({
      query: (payload) => ({
        url: `/${payload.url}`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    postSignedUp: builder.mutation({
      query: (payload) => ({
        url: "/auth/signup",
        method: "PUT",
        body: payload.data,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    postNewProduct: builder.mutation({
      query: (payload) => ({
        url: `/products/postproduct`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    postEditProduct: builder.mutation({
      query: ({ data, id }) => ({
        url: `products/product/${id}`,
        providesTags: ["Products,Edit"],

        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
      invalidatesTags: ["Products"],
    }),
    postDeleteProduct: builder.mutation({
      query: (id) => ({
        url: `products/product/${id}`,
        method: "DELETE",
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useGetHeroImagesQuery,
  useGetStyleImagesQuery,
  useGetContactInfoQuery,
  usePostSignedInMutation,
  usePostNewProductMutation,
  usePostEditProductMutation,
  usePostDeleteProductMutation,
  usePostSignedUpMutation,
  usePostHeroImagesMutation,
  usePostStyleImagesMutation,
  usePostContactMutation,
} = poiseApi;
