// import Spin from "antd/es/spin";
import { message } from "antd";
import UploadImage from "Components/upload/UploadImage";
import { ChangeEvent, FormEvent, useState } from "react";
import {
  usePostHeroImagesMutation,
  useGetHeroImagesQuery,
} from "redux/actions/services";
import { useNavigate } from "react-router-dom";

function EditSlider() {
  const [file, setFile] = useState("");
  const [heroImage, setheroImage] = useState({
    heroImage1: "",
    heroImage2: "",
    heroImage3: "",
    heroImage4: "",
  });

  // const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [postHeroImagesMutation] = usePostHeroImagesMutation();
  const { data } = useGetHeroImagesQuery("/");
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setheroImage({ ...heroImage, [name]: value });
  };
  const payload: any = {
    data: {
      image: [
        heroImage.heroImage1,
        heroImage.heroImage2,
        heroImage.heroImage3,
        heroImage.heroImage4,
      ],
    },
    url: "heroImages/heroImage",
  };
  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await postHeroImagesMutation(payload)
      .unwrap()
      .then((res) => {
        message.success(res.message);
        // setStatus(res.message);
        setIsLoading(false);
        navigate("/");
      })
      .catch((error) => {
        // setStatus("error");
        setIsLoading(false);
        // console.log("Error occurred:", error);
      });
  };
  return (
    <div>
      <form onSubmit={submitHandler} className="w-[50%] mx-auto">
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="heroImage1" className="basis-[13%] mr-2">
            Slider Picture 1:
          </label>
          <input
            type="heroImage1"
            name="heroImage1"
            placeholder="Enter heroImage1"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            min="4"
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="heroImage2" className="basis-[13%] mr-2">
            Slider Picture 2:
          </label>
          <input
            type="text"
            name="heroImage2"
            placeholder="Enter heroImage2"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={4}
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="heroImage2" className="basis-[13%] mr-2">
            Slider Picture 3:
          </label>
          <input
            type="text"
            name="heroImage3"
            placeholder="Enter heroImage3"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={4}
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="heroImage2" className="basis-[13%] mr-2">
            Slider Picture 4:
          </label>
          <input
            type="text"
            name="heroImage4"
            placeholder="Enter heroImage4"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={4}
            required
            onChange={changeHandler}
          />
        </div>
        {file ? (
          <div className="w-full p-2 rounded-xl bg-white">
            <p className="w-full break-words text-xs">{file}</p>
          </div>
        ) : null}
        <div>
          <UploadImage setFile={setFile} AltText="Upload Hero Product" />
        </div>
        {/* <button className="w-[60%] my-8 bg-button p-2 rounded-md hover:bg-button_hover hover:text-white md:p-3">
          {isLoading ? <Spin size="large" /> : "upload"}
        </button> */}
        <button className="w-full my-8 bg-button p-2 rounded-md hover:bg-button_hover hover:text-white md:p-3">
          {isLoading ? "Loading..." : "Update Slider Images"}
        </button>
      </form>
    </div>
  );
}

export default EditSlider;
