import { message } from "antd";
import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePostContactMutation } from "redux/actions/services";

function EditContactInfo() {
  const [contactInfo, setContactInfo] = useState({
    country: "",
    phoneNumber: "",
    email: "",
    openingday: "",
    closingDay: "",
    openingTime: 0,
    closingTime: 0,
  });

  // const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [postcontactInfosMutation] = usePostContactMutation();
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
  };
  const payload: any = {
    data: {
      country: contactInfo.country,
      email: contactInfo.email,
      phoneNumber: contactInfo.phoneNumber,
      weekOpeningDay: contactInfo.openingday,
      weekClosingDay: contactInfo.closingDay,
      dayOpeningTime: contactInfo.openingTime,
      dayClosingTime: contactInfo.closingTime,
    },
    url: "contacts/postContact",
  };
  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await postcontactInfosMutation(payload)
      .unwrap()
      .then((res) => {
        message.success(res.message);
        // setStatus(res.message);
        setIsLoading(false);
        navigate("/");
      })
      .catch((error) => {
        // setStatus("error");
        setIsLoading(false);
        // console.log("Error occurred:", error);
      });
  };
  return (
    <div>
      <form onSubmit={submitHandler} className="w-[50%] mx-auto">
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="country" className="basis-[13%] mr-2">
            Country:
          </label>
          <input
            type="country"
            name="country"
            placeholder="Enter country"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            min="4"
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="phoneNumber" className="basis-[13%] mr-2">
            PhoneNumber:
          </label>
          <input
            type="text"
            name="phoneNumber"
            placeholder="Enter phoneNumber"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={4}
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="email" className="basis-[13%] mr-2">
            Email:
          </label>
          <input
            type="email"
            name="email"
            placeholder="Enter email"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={4}
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="phoneNumber" className="basis-[13%] mr-2">
            Openingday:
          </label>
          <input
            type="text"
            name="openingday"
            placeholder="Enter openingday"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={3}
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="closingDay" className="basis-[13%] mr-2">
            closingDay:
          </label>
          <input
            type="text"
            name="closingDay"
            placeholder="Enter closingDay"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={3}
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="openingTime" className="basis-[13%] mr-2">
            OpeningTime:
          </label>
          <input
            type="text"
            name="openingTime"
            placeholder="Enter openingTime"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={4}
            required
            onChange={changeHandler}
          />
        </div>
        <div className="w-full my-8 sm:flex justify-between">
          <label htmlFor="closingTime" className="basis-[13%] mr-2">
            ClosingTime:
          </label>
          <input
            type="text"
            name="closingTime"
            placeholder="Enter closingTime"
            className="block w-full sm:basis-[80%] bg-transparent border-b-[1px] border-button focus:outline-none focus:bg-transparent"
            minLength={4}
            required
            onChange={changeHandler}
          />
        </div>
        <button
          type="submit"
          className="w-full my-8 bg-button p-2 rounded-md hover:bg-button_hover hover:text-white md:p-3"
        >
          {isLoading ? "Loading..." : "Update contact info"}
        </button>
      </form>
    </div>
  );
}

export default EditContactInfo;
